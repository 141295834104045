* {
    box-sizing: border-box;
}
body {
    font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 15px;
    line-height: 1.42857143;
    color: #2c3e50;
    background-color: #ffffff;
    margin: 0;
}


