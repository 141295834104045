.validation-message, h4.wcag-err {
  margin-top: 5px;
  font-size: 14px;
  color: $ERROR;
}
// Error messages should be shown in the specified color.
.wcag-err {
  color: $ERROR;
}

// Remove blue outline for focused message element, so it's not so ugly for the 99.9999...% of users who aren't visually impaired.
.wcag-err, .wcag-msg  {
  outline:none;
}
