.vertical-center-container {
  display: table;
  height: 100%;
  width: 100%;
  margin-top: 10px;
}

.vertical-center-form {
  display: table-cell;
  vertical-align: middle;
  @media only screen and (max-width: 768px) {
    padding-top: 25px;
  }
}

button {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    align-items: flex-start;
}

label {
}

input {
  display: block;
  width: 100%;
}

.input-valid {
  margin-top: -30px;
  margin-right: 10px;
  color: $VALID;
}

.input-error {
  margin-top: -30px;
  margin-right: 10px;
  color: $ERROR;
}

.border-width-1px {
  border-width: 1px;
}

.form-group {
  margin-bottom: 8px; // overrides for validation message below
}

// Stolen from stackoverflow: https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
// during addition of Password Reset form related to 11951.
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

