.loader {
  height:80px;
  width:80px;
  position:absolute;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -40px;
}

.loader .spin {
  height:80px;
  width:80px;
  -webkit-animation:myspin 1s ease infinite;
  animation:myspin 1s ease infinite;
}

.loader .spin, .loader .spin:before {
  box-sizing:border-box;
  border:5px solid blue;
  border-left-color:#3369E8;
  border-top-color:#D50F25;
  border-right-color:#009925;
  border-bottom-color:#EEB211;
  border-radius:50%;
  position:absolute;
  display:block;
}

.loader .spin:before {
  content:" ";
  left:50%;
  top:50%;
  height:96px;
  width:96px;
  margin:-48px;
  border-width:6px;
  border-left-color:rgba(0,0,0,0);
  border-right-color:rgba(0,0,0,0);
  border-top-color:rgba(0,0,0,0);
  border-bottom-color:rgba(0,0,0,.2);
  opacity:1;
  -webkit-animation:myspin 1s reverse ease infinite;
  animation:myspin 1s reverse ease infinite;
}

@keyframes myspin {
  from {
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg);
  }

  to {
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
  }
}

