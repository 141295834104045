@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.sample-background {
    background-image: url('../../assets/images/login-background-blue.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.sample-link-to-perfection-learning {
    position: relative;
    bottom: 30px;
    display: flex;
    justify-content: center;
}

.pl-background {
    background-image: url('../../assets/images/login-background-green.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.db-background {
    background-image: url('../../assets/images/login-background-red.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

main.username-password, main.forgot-password {
    min-height: 300px;
}

.error-placeholder::placeholder {
    color: $ERROR;
}

.login-wrapper {
    height: 100vh;
    width: 100vw;
}

.login-wrapper .login-card-container {
    padding: 0;
    margin-bottom: 0;
    border: none;
    height: 475px;
	@media only screen and (max-width: 768px) {
		height: 100vh;
	}
    box-shadow: 21px 10px 50px rgba(90, 90, 90, 0.51);
}

.login-wrapper .panel-heading {
    background-color: #ffffff;
    border-color: #ffffff;
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-bottom-color: #195F76;
    color: #195F76;
}

.login-wrapper {
  .panel-body {
    position: absolute;
    width: 100%;
    top: 0;
    height: calc(100%);
  }
  .vertical-center-form {
	display: table-cell;
	vertical-align: middle;
	@media only screen and (max-width: 768px) {
		padding-top: 25px;
	}
  }
}

.show-error {
    visibility: visible;
}

.hide-error {
    visibility: hidden;
}

.login-wrapper button {
    margin: 2px;
}

.login-wrapper .dbts-title {
    //color: #1d7fc7; // Color picked from https://trello.com/c/Q8sQwJC7/30-dbts-program-series-image attachment.
    color: black; // Specified in https://trello.com/c/mIiwoxsD/1264-add-dbts-support-to-new-user-interface#comment-5ed6bd5d4d63b8094af48de4
}
.login-wrapper .bold {
    font-weight: bold;
}
.login-wrapper .center {
    display: flex;
    justify-content: center;
}
.login-wrapper h3 {
    color: #0D5D79;
}
.login-wrapper .wcag-h2-mimic-h3 {
    font-size: 26px;
    line-height: 28.6px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 15px 0;
}

.login-wrapper .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: black;
}

.login-wrapper .footer .privacy-terms {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 50px;
    color: white;
    font-family: "Inter";
    font-size: 14px; // 20002
}

.login-wrapper .footer .privacy-terms a,
.login-wrapper .footer .privacy-terms a:hover {
    color: white;
    text-decoration: none;
}

.panel {
    height: 400px;
    background-color: #ffffff;
    border-radius: 4px;
	@media (max-width: 768px) {
        width: 100%;
    }
	@media (min-width: 769px) {
        width: 500px;
    }
}

.panel.panel-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

h3 {
    font-size: 26px;
    margin-bottom: 10.5px;
    font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
}
h3 {
    display: block;
    font-size: 26px;
/*
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
*/
    font-weight: bold;
}

.panel-body h3 {
    margin-top: 0;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.panel-heading-logo {
    height:80px;
}

.mathx-logo {
	width: 100px;
	margin-top: 0px;
	min-height:30px;
}

.login-wrapper .mathx-logo {
    display:block;
    margin-top: 2px;
    height: 60px;
    width: 325px;
    margin-left: auto;
    margin-right: auto;
/*
    @media only screen and (max-width: 768px) {
        max-width: 25%;
    }
*/
}
 .text-center {
    text-align: center;
}

.db-logo {
    margin-top: 2px;
    height: 90px;
    width: 190px;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 768px) {
        max-width: 25%;
    }
}

.panel-body {
    padding: 15px;
}

.fix-bottom {
    bottom: 0; left: 0;
    position: absolute;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
}

